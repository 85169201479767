import React from 'react'
import {RichText, Link} from 'prismic-reactjs'
import Shape from './shape.js'
import linkResolver from '../utils/linkResolver'
import InlineSVG from './inlineSVG'

import './features.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const Features = (props) => {

  if(!props.features) return null;
  if(!props.features.primary) return null;

  return (
    <section className="kb__features">
      <div className="kb__container--full">
      
        {props.features.primary.main_heading &&
          <div className="kb__grid-center">
            <div className="kb__col-10 kb__features-blurb">

            {props.features.primary.main_heading &&
              <h3>{props.features.primary.main_heading}</h3>
            }

            {props.features.primary.sub_head &&
              <h4>{props.features.primary.sub_head}</h4>
            }
            </div>
          </div>
        }

        {props.features.primary.features_section_heading && props.features.primary.features_section_heading[0].text !== "" &&
          <div className="kb__grid-center">
            <div className="kb__col-8_sm-10 kb__features-blurb">

              {props.features.primary.features_section_heading &&
                RichText.render(props.features.primary.features_section_heading)
              }

              {props.features.primary.features_section_sub_heading &&
                <p className="big-body">{RichText.asText(props.features.primary.features_section_sub_heading)}</p>
              }
              
            </div>
          </div>
        }

        

        {props.features.fields && props.features.fields[0].scroll_card_icon &&
          <div className="kb__scroll-cards">
            <div className="kb__grid-center">
              <div className="kb__col-10_sm-12">
                <div className="kb__grid-center">

                  {props.features.fields.map((feature,i) => {

                      return(
                        <div key={i} className="kb__col-2_md-3_sm-6">
                          <a className="kb__scroll-card" href={"#feature" + i }>

                            {feature.scroll_card_icon &&
                              <InlineSVG classes="kb__scroll-card-icon" url={feature.scroll_card_icon.url} />
                            }

                            {feature.scroll_to_card_title &&
                              <span>{feature.scroll_to_card_title}</span>
                            }

                            <span className="kb__scroll-card-arrow">
                              <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                <path d="M27.8188 11.8062L29.2063 13.182C29.7938 13.7646 29.7938 14.7066 29.2063 15.283L17.0626 27.3309C16.4751 27.9134 15.5251 27.9134 14.9438 27.3309L2.79384 15.283C2.20634 14.7004 2.20634 13.7584 2.79384 13.182L4.18134 11.8062C4.77509 11.2174 5.74385 11.2298 6.3251 11.831L13.5001 19.2989V1.48739C13.5001 0.66313 14.1688 0 15.0001 0L17.0001 0C17.8313 0 18.5001 0.66313 18.5001 1.48739V19.2989L25.6751 11.831C26.2563 11.2236 27.2251 11.2112 27.8188 11.8062Z" fill="#3A3A3A"/>
                                </g>
                                <defs>
                                <clipPath id="clip0">
                                <rect width="27.7647" height="32" fill="white" transform="translate(32) rotate(90)"/>
                                </clipPath>
                                </defs>
                              </svg>
                            </span>

                          </a>
                        </div> 
                      )
                    })

                  }

                </div>
              </div>
            </div>
          </div>
        }


        {props.features.fields &&
          <div className="kb__feature-blocks">
            <div className="kb__grid-center">

              {
                props.features.fields.map((feature,i) => {
                  return(
                    <div key={i} className="kb__col-10_sm-12 kb__feature-block" id={"feature" + i}>
                      <div className="kb__grid-center-noGutter">
                        
                        {feature.feature &&
                          <div className="kb__col-12">
                            <h3>{feature.feature}</h3>
                          </div>
                        }

                        <div className="kb__col-12">
                          <div className="kb__grid-center kb__feature-block-indv">
                            <div className="kb__col-6_sm-12 kb__feature-image">

                              {feature.feature_image &&
                                <img className="kb__feature-screenshot"src={feature.feature_image.url} alt={feature.feature_image.alt} />
                              }
                              
                              {feature.shape_background &&
                                <div className="shape-container">
                                  <Shape shape={feature.shape_background} />
                                </div>  
                              }
                            </div>

                          
                            <div className="kb__col-6_sm-12 kb__feature-info">
                              <div className="kb__grid-center-middle">
                                <div className="kb__col-12_sm-12">
                                  

                                  {feature.feature_title &&
                                    <h4 className="purple">{feature.feature_title}</h4>
                                  }

                                  {feature.feature_blurb && props.uid !== "about-us" &&
                                    <div className="big-body">{RichText.asText(feature.feature_blurb)}</div>
                                  }

                                  {feature.feature_blurb && props.uid === "about-us" &&
                                    RichText.render(feature.feature_blurb)
                                  }

                                  <div className="kb__feature-actions">
                                    {feature.cta_1_text && feature.cta_1_link &&
                                      <a className="kb__button--secondary" href={RichLink(feature.cta_1_link)}>{feature.cta_1_text}</a>
                                    }

                                    {feature.cta_2_text && feature.cta_2_link &&
                                      <a className="kb__button--primary" href={RichLink(feature.cta_2_link)}>{feature.cta_2_text}</a>
                                    }
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  )
                })
              }

            </div>
          </div>
        }

      </div>
    </section>
  )
}

export default Features
