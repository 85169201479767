import React from 'react'
import InlineSVG from './inlineSVG'

import { Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'

import './linkBlock.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const LinkBlock = (props) => {

  const linkBlockLink = props.linkblock.primary.link_block_link;

  return(
    <section className="kb__link-block">
      <a href={RichLink(linkBlockLink)} title={props.linkblock.primary.link_block_text}>
        <div className="kb__container">
          <div className="kb__grid-noGutter-center"> 
            <div className="kb__col-12 kb__link-block-content">
              <h3>{props.linkblock.primary.link_block_text}</h3>
              <InlineSVG url={props.linkblock.primary.link_block_icon.url} />
            </div>
          </div>
        </div>
      </a>
    </section>
  )
}

export default LinkBlock