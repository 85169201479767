import React from 'react'
import {RichText} from 'prismic-reactjs'
import IconCard from './iconCard'

import './iconGrid.scss'

const IconGrid = (props) => {

  var columnClass = 'kb__col'
  
  if(props.grid.primary.cols_desktop){
    columnClass = columnClass+'-'+ props.grid.primary.cols_desktop
  }

  if(props.grid.primary.cols_tablet){
    columnClass = columnClass+'_md-'+ props.grid.primary.cols_tablet
  }

  if(props.grid.primary.cols_mobile){
    columnClass = columnClass+'_sm-'+ props.grid.primary.cols_mobile
  }

  var has_dropshadow = false

  if(props.grid.primary.has_dropshadow){
    has_dropshadow = true
  }

  var has_border = false

  if(props.grid.primary.has_border){
    has_border = true
  }
  
  var has_gutter = false

  if(props.grid.primary.grid_with_gutter){
    has_gutter = true
  }

  var inset_grid = props.grid.primary.inset_grid

  var containerClass = 'kb__container'

  if(props.grid.primary.inset_grid === true){
    containerClass = containerClass + "--narrow"
  } else if(props.grid.primary.extra_inset_grid === true) {
    containerClass = containerClass + "--extra-narrow"
  } else if(props.grid.primary.has_border === true){
    containerClass = containerClass + "--full-bleed"
  }

  if(props.grid.primary.inset_grid === false && props.grid.primary.extra_inset_grid === false  && props.grid.primary.has_border === false){
    containerClass = containerClass + "--full"
  }

  var gridClass = 'kb__grid-center'

  if(props.grid.primary.has_border === true){
    gridClass = gridClass + "-noGutter"
  }

  return(
    <section className="kb__icon-grid" data-has-dropshadow={has_dropshadow}  data-has-border={has_border}  data-has-gutter={has_gutter} data-inset-grid={inset_grid}>

      {props.grid.primary.icon_grid_title &&
        <div className="kb__container">
          <div className="kb__icon-grid-title">{RichText.render(props.grid.primary.icon_grid_title)}</div>
        </div>
      }

      <div className={containerClass}>
        <div className={gridClass} >

            {
              props.grid.fields.map((column,i) => {
                return(
                  <div key={i} className={columnClass}>
                    <IconCard column={column} />
                  </div>
                )
              })
            }
          </div>
      </div>
    </section>
  )
}

export default IconGrid