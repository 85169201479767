import Axios from 'axios';

var marketplaceUrl = 'https://kickboostersb.me'

if(process.env.NODE_ENV === "production"){
    marketplaceUrl = '/';
}

const client = Axios.create({
  baseURL: marketplaceUrl
});

/**
 * Valid options used to sort the campaigns returend
 */
const SORT_OPTIONS = Object.freeze([
  {
    value: 'latest',
    displayValue: 'Newest Arrivals',
  },
  {
    value: 'staff_pick',
    displayValue: 'Our Latest Picks',
  },
  {
    value: 'most_funded',
    displayValue: 'Most Funded (Crowdfunding Only)',
  },
  {
    value: 'ending_soon',
    displayValue: 'Ending Soon (Crowdfunding Only)',
  },
  {
    value: 'commission',
    displayValue: 'Commission: High to Low',
  },
]);

/**
 * Valid types used to filter the type of campaigns returned
 */
const CAMPAIGN_TYPES = Object.freeze([
  {
    value: 'all',
    displayValue: 'All Campaigns',
    icon: '<svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.1547 0.833993L9.09377 7.04024L2.24533 8.03868C1.01721 8.21681 0.525022 9.73087 1.41565 10.5981L6.37033 15.4262L5.19846 22.2465C4.98752 23.4793 6.28596 24.4027 7.37346 23.8262L13.5 20.6059L19.6266 23.8262C20.7141 24.3981 22.0125 23.4793 21.8016 22.2465L20.6297 15.4262L25.5844 10.5981C26.475 9.73087 25.9828 8.21681 24.7547 8.03868L17.9063 7.04024L14.8453 0.833993C14.2969 -0.272257 12.7078 -0.28632 12.1547 0.833993Z" fill="#3A3A3A"/></svg>',
    categories: [
      {
          value: "activewear",
          displayValue: "Activewear"
      },
      {
          value: "art",
          displayValue: "Art"
      },
      {
          value: "automotive",
          displayValue: "Automotive"
      },
      {
          value: "beauty",
          displayValue: "Beauty & Personal Care"
      },
      {
          value: "crafts",
          displayValue: "Crafts"
      },
      {
          value: "design",
          displayValue: "Design"
      },
      {
          value: "education",
          displayValue: "Education"
      },
      {
          value: "everyday",
          displayValue: "Everyday Carry"
      },
      {
          value: "fashion",
          displayValue: "Fashion"
      },
      {
          value: "film&video",
          displayValue: "Film & Video"
      },
      {
          value: "food",
          displayValue: "Food"
      },
      {
          value: "games",
          displayValue: "Games"
      },
      {
          value: "health",
          displayValue: "Health"
      },
      {
          value: "home",
          displayValue: "Home Goods"
      },
      {
          value: "music",
          displayValue: "Music"
      },
      {
          value: "outdoors",
          displayValue: "Outdoors"
      },
      {
          value: "photography",
          displayValue: "Photography"
      },
      {
          value: "publishing",
          displayValue: "Publishing"
      },
      {
          value: "sporting",
          displayValue: "Sporting Goods"
      },
      {
          value: "technology",
          displayValue: "Technology"
      },
      {
          value: "toys",
          displayValue: "Toys, Kids, & Baby"
      },
      {
          value: "travel",
          displayValue: "Travel"
      }
    ],
  },
  {
    value: 'staff_picks',
    displayValue: 'Staff Picks',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.75 1.5H2.25C1.00734 1.5 0 2.50734 0 3.75V20.25C0 21.4927 1.00734 22.5 2.25 22.5H21.75C22.9927 22.5 24 21.4927 24 20.25V3.75C24 2.50734 22.9927 1.5 21.75 1.5ZM21.4688 20.25H2.53125C2.45666 20.25 2.38512 20.2204 2.33238 20.1676C2.27963 20.1149 2.25 20.0433 2.25 19.9688V4.03125C2.25 3.95666 2.27963 3.88512 2.33238 3.83238C2.38512 3.77963 2.45666 3.75 2.53125 3.75H21.4688C21.5433 3.75 21.6149 3.77963 21.6676 3.83238C21.7204 3.88512 21.75 3.95666 21.75 4.03125V19.9688C21.75 20.0433 21.7204 20.1149 21.6676 20.1676C21.6149 20.2204 21.5433 20.25 21.4688 20.25ZM19.5 15.9375V17.0625C19.5 17.3731 19.2481 17.625 18.9375 17.625H9.5625C9.25186 17.625 9 17.3731 9 17.0625V15.9375C9 15.6269 9.25186 15.375 9.5625 15.375H18.9375C19.2481 15.375 19.5 15.6269 19.5 15.9375ZM19.5 11.4375V12.5625C19.5 12.8731 19.2481 13.125 18.9375 13.125H9.5625C9.25186 13.125 9 12.8731 9 12.5625V11.4375C9 11.1269 9.25186 10.875 9.5625 10.875H18.9375C19.2481 10.875 19.5 11.1269 19.5 11.4375ZM19.5 6.9375V8.0625C19.5 8.37314 19.2481 8.625 18.9375 8.625H9.5625C9.25186 8.625 9 8.37314 9 8.0625V6.9375C9 6.62686 9.25186 6.375 9.5625 6.375H18.9375C19.2481 6.375 19.5 6.62686 19.5 6.9375ZM7.6875 7.5C7.6875 8.43197 6.93197 9.1875 6 9.1875C5.06803 9.1875 4.3125 8.43197 4.3125 7.5C4.3125 6.56803 5.06803 5.8125 6 5.8125C6.93197 5.8125 7.6875 6.56803 7.6875 7.5ZM7.6875 12C7.6875 12.932 6.93197 13.6875 6 13.6875C5.06803 13.6875 4.3125 12.932 4.3125 12C4.3125 11.068 5.06803 10.3125 6 10.3125C6.93197 10.3125 7.6875 11.068 7.6875 12ZM7.6875 16.5C7.6875 17.432 6.93197 18.1875 6 18.1875C5.06803 18.1875 4.3125 17.432 4.3125 16.5C4.3125 15.568 5.06803 14.8125 6 14.8125C6.93197 14.8125 7.6875 15.568 7.6875 16.5Z" fill="#3A3A3A"/></svg>',
    categories: [
      {
          value: "beauty",
          displayValue: "Beauty & Personal Care"
      },
      {
          value: "design",
          displayValue: "Design"
      },
      {
          value: "education",
          displayValue: "Education"
      },
      {
          value: "everyday",
          displayValue: "Everyday Carry"
      },
      {
          value: "fashion",
          displayValue: "Fashion"
      },
      {
          value: "food",
          displayValue: "Food"
      },
      {
          value: "games",
          displayValue: "Games"
      },
      {
          value: "health",
          displayValue: "Health"
      },
      {
          value: "home",
          displayValue: "Home Goods"
      },
      {
          value: "outdoors",
          displayValue: "Outdoors"
      },
      {
          value: "sporting",
          displayValue: "Sporting Goods"
      },
      {
          value: "technology",
          displayValue: "Technology"
      },
      {
          value: "travel",
          displayValue: "Travel"
      }
    ],
  },
  {
    value: 'crowdfunding',
    displayValue: 'Crowdfunding',
    icon: '<svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 9.5C6.15469 9.5 7.5 8.15469 7.5 6.5C7.5 4.84531 6.15469 3.5 4.5 3.5C2.84531 3.5 1.5 4.84531 1.5 6.5C1.5 8.15469 2.84531 9.5 4.5 9.5ZM25.5 9.5C27.1547 9.5 28.5 8.15469 28.5 6.5C28.5 4.84531 27.1547 3.5 25.5 3.5C23.8453 3.5 22.5 4.84531 22.5 6.5C22.5 8.15469 23.8453 9.5 25.5 9.5ZM27 11H24C23.175 11 22.4297 11.3328 21.8859 11.8719C23.775 12.9078 25.1156 14.7781 25.4062 17H28.5C29.3297 17 30 16.3297 30 15.5V14C30 12.3453 28.6547 11 27 11ZM15 11C17.9016 11 20.25 8.65156 20.25 5.75C20.25 2.84844 17.9016 0.5 15 0.5C12.0984 0.5 9.75 2.84844 9.75 5.75C9.75 8.65156 12.0984 11 15 11ZM18.6 12.5H18.2109C17.2359 12.9688 16.1531 13.25 15 13.25C13.8469 13.25 12.7688 12.9688 11.7891 12.5H11.4C8.41875 12.5 6 14.9188 6 17.9V19.25C6 20.4922 7.00781 21.5 8.25 21.5H21.75C22.9922 21.5 24 20.4922 24 19.25V17.9C24 14.9188 21.5812 12.5 18.6 12.5ZM8.11406 11.8719C7.57031 11.3328 6.825 11 6 11H3C1.34531 11 0 12.3453 0 14V15.5C0 16.3297 0.670312 17 1.5 17H4.58906C4.88438 14.7781 6.225 12.9078 8.11406 11.8719Z" fill="#464646"/></svg>',
    categories: [
      {
          value: "crafts",
          displayValue: "Crafts"
      },
      {
          value: "design",
          displayValue: "Design"
      },
      {
          value: "education",
          displayValue: "Education"
      },
      {
          value: "fashion",
          displayValue: "Fashion"
      },
      {
          value: "film&video",
          displayValue: "Film & Video"
      },
      {
          value: "food",
          displayValue: "Food"
      },
      {
          value: "games",
          displayValue: "Games"
      },
      {
          value: "health",
          displayValue: "Health"
      },
      {
          value: "music",
          displayValue: "Music"
      },
      {
          value: "publishing",
          displayValue: "Publishing"
      },
      {
          value: "technology",
          displayValue: "Technology"
      },
      {
          value: "travel",
          displayValue: "Travel"
      }
    ],
  },
  {
    value: 'ecommerce',
    displayValue: 'eCommerce',
    icon: '<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 7.5V6C16.5 2.69156 13.8084 0 10.5 0C7.19156 0 4.5 2.69156 4.5 6V7.5H0V20.25C0 22.3211 1.67892 24 3.75 24H17.25C19.3211 24 21 22.3211 21 20.25V7.5H16.5ZM7.5 6C7.5 4.34578 8.84578 3 10.5 3C12.1542 3 13.5 4.34578 13.5 6V7.5H7.5V6ZM15 11.625C14.3787 11.625 13.875 11.1213 13.875 10.5C13.875 9.87867 14.3787 9.375 15 9.375C15.6213 9.375 16.125 9.87867 16.125 10.5C16.125 11.1213 15.6213 11.625 15 11.625ZM6 11.625C5.37867 11.625 4.875 11.1213 4.875 10.5C4.875 9.87867 5.37867 9.375 6 9.375C6.62133 9.375 7.125 9.87867 7.125 10.5C7.125 11.1213 6.62133 11.625 6 11.625Z" fill="#464646"/></svg>',
    categories: [
      {
          value: "activewear",
          displayValue: "Activewear"
      },
      {
          value: "art",
          displayValue: "Art"
      },
      {
          value: "automotive",
          displayValue: "Automotive"
      },
      {
          value: "beauty",
          displayValue: "Beauty & Personal Care"
      },
      {
          value: "design",
          displayValue: "Design"
      },
      {
          value: "education",
          displayValue: "Education"
      },
      {
          value: "everyday",
          displayValue: "Everyday Carry"
      },
      {
          value: "fashion",
          displayValue: "Fashion"
      },
      {
          value: "food",
          displayValue: "Food"
      },
      {
          value: "games",
          displayValue: "Games"
      },
      {
          value: "health",
          displayValue: "Health"
      },
      {
          value: "home",
          displayValue: "Home Goods"
      },
      {
          value: "music",
          displayValue: "Music"
      },
      {
          value: "outdoors",
          displayValue: "Outdoors"
      },
      {
          value: "photography",
          displayValue: "Photography"
      },
      {
          value: "sporting",
          displayValue: "Sporting Goods"
      },
      {
          value: "technology",
          displayValue: "Technology"
      },
      {
          value: "toys",
          displayValue: "Toys, Kids, & Baby"
      },
      {
          value: "travel",
          displayValue: "Travel"
      }
    ],
  },
]);

/**
 * Gets campaigns filtered by the provided params
 * 
 * @param {{
 *  sort: string;
 *  type: string;
 *  categories: []string;
 *  search_term?: string;
 * }} params
 */
const getCampaigns = async (params) => {
  params = {
    all_categories: !params.categories?.length ? true : false,
    ...params,
  };

  const { data } = await client.get('/marketplace.json', {params});
  const campaigns = data.campaigns;
  campaigns.totalPages = data.total_pages;
  campaigns.totalCount = data.total_count;
  
  return campaigns;
}

export default {
  SORT_OPTIONS,
  CAMPAIGN_TYPES,
  getCampaigns,
}