import React from "react"
import {RichText, Link} from 'prismic-reactjs'

import linkResolver from '../utils/linkResolver'

import './richTextBlock.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const RichTextBlock = (props) => {

  var hasBigBody = true;

  if(props.richTextBlock.primary.big_body_ === false){
    hasBigBody = false;
  }

  var innerAnchor;

  if(props.richTextBlock.primary.rich_text_cta_text){
    innerAnchor = props.richTextBlock.primary.rich_text_cta_text.replace(/\s/g, '-').toLowerCase();
  } else {
    innerAnchor = "rich-text-"+props.count;
  }

  var handledLink;

  if(props.richTextBlock.primary.rich_text_cta_link){
    if(props.richTextBlock.primary.cta_internal_id){
      handledLink = RichLink(props.richTextBlock.primary.rich_text_cta_link)+'#'+props.richTextBlock.primary.cta_internal_id;
    }else{
      handledLink =  RichLink(props.richTextBlock.primary.rich_text_cta_link)
    }
  }

  return (
    <section className="kb__rich-text-block" id={innerAnchor} data-center-layout={props.richTextBlock.primary.center_layout}>
      <div className="kb__container">
        <div className="kb__grid-center">

          <div className="kb__col-8_sm-12">

            {props.richTextBlock.primary.rich_text_title &&
              <h4>{RichText.asText(props.richTextBlock.primary.rich_text_title)}</h4>
            }

            {props.richTextBlock.primary.richtext && 

              hasBigBody
                ? <div className="big-body">{RichText.render(props.richTextBlock.primary.richtext)}</div>
                : RichText.render(props.richTextBlock.primary.richtext)
            }

          </div>

          {props.richTextBlock.primary.rich_text_cta_link &&
            <div className="kb__col-8_sm-12">
              <div className="kb__actions">

                {props.richTextBlock.primary.rich_text_cta_link && props.richTextBlock.primary.rich_text_cta_text &&
                  <a href={handledLink} className="kb__button--primary kb__button--large">{props.richTextBlock.primary.rich_text_cta_text}</a>
                }

                {props.richTextBlock.primary.rich_text_cta_link_2 && props.richTextBlock.primary.rich_text_cta_text_2 &&
                  <a href={RichLink(props.richTextBlock.primary.rich_text_cta_link_2)} className="kb__button--secondary kb__button--large">{props.richTextBlock.primary.rich_text_cta_text_2}</a>
                }

              </div>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default RichTextBlock
