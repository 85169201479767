import React from 'react'
import {RichText, Link} from 'prismic-reactjs'
import InlineSVG from './inlineSVG'
import linkResolver from '../utils/linkResolver'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const iconCard = (props) => {

  var card = (
    <div className="kb__card">
      {props.column.icon_card_icon &&
        <InlineSVG url={props.column.icon_card_icon.url} />
      }

      {props.column.icon_card_image &&
        <img src={props.column.icon_card_image.url} alt={props.column.icon_card_image.alt} />
      }

      {props.column.icon_card_title &&
        RichText.render(props.column.icon_card_title)
      }

      {props.column.icon_card_description &&
        <p className="big-body">{RichText.asText(props.column.icon_card_description)}</p>
      }

      {props.column.icon_card_link_text &&
        <a className="big-body" href={RichLink(props.column.icon_card_link)} title={props.column.icon_card_link_text}>{props.column.icon_card_link_text}</a>
      }
    </div>
  )

  if (props.column.icon_card_link && !props.column.icon_card_link_text){
    card = (
      <a className="kb__card-link" href={RichLink(props.column.icon_card_link)} title="Learn more">
        {card}
      </a>
    )
  }

  return(
    <>
    {card}
    </>
  )

}

export default iconCard