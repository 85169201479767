import React, { useCallback, useState, useEffect } from 'react';
import {RichText} from 'prismic-reactjs'

import MarketplaceService from '../services/MarketplaceService';

import SwiperCore, { Pagination, A11y, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import IconChevronLeft from '../images/icon-chevron-left.svg'
import IconChevronRight from '../images/icon-chevron-right.svg'

import './marketplace.scss'

SwiperCore.use([Pagination, A11y, Navigation, Autoplay]);


const Marketplace = (props) => {

  const [ campaigns, setCampaigns ] = useState([]);

  const getCampaigns = useCallback(async () => {
    const campaigns = await MarketplaceService.getCampaigns({ type: 'featured', sort: 'latest'});
    setCampaigns(campaigns);
  }, [setCampaigns]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  return(

    <section className="kb__marketplace">
      <div className="kb__container">
        <div className="kb__banner-content">
          <div className="kb__grid-center">
            
            <div className="kb__col-10_sm-12">
              <div className="kb__marketplace-blurb">
                {RichText.render(props.marketplace.primary.marketplace_title)}
              </div>

              {campaigns.length > 0 && (
                <div className="kb__campaigns-slider">
                  <div className="kb__campaigns">
                  
                    <Swiper
                      autoHeight={true}
                      autoplay={{delay: 4000, disableOnInteraction: true}}
                      pagination={{el: '.kb__campaigns-pagination', clickable: true}}
                      navigation={{nextEl: '.kb__campaigns-next', prevEl: '.kb__campaigns-prev'}}
                      grabCursor={true}
                      loop={true}
                    >
                      {campaigns.map(campaign => 
                        <SwiperSlide key={campaign.campaign_id}>
                          <div className="kb__grid-noGutter">
                            <div className="kb__col-12">
                              <img className="kb__campaign-image" src={campaign.image_medium_url} alt={campaign.name} />
                            </div>
                          </div>
                          <div className="kb__campaign-details">
                            <div className="kb__grid-middle-spaceBetween">
                              <div className="kb__col-8_sm-12">
                                <div className="kb__campaign-info">
                                  <h4>{campaign.name}</h4>
                                  <p className="big-body">{campaign.blurb}</p>
                                </div>
                              </div>
                              <div className="kb__col-4_md-12 kb__actions">
                                <a className="kb__button--secondary" href={campaign.kickbooster_redirect_url} target="_blank" rel="noreferrer" title="View campaign">View project</a>
                                <a className="kb__button--primary" href={campaign.booster_page_url} title="Boost this campaign">Boost project</a>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      )}
                    </Swiper>
                  
              
                  </div>

                  <div className="kb__campaigns-nav">
                    <button className="kb__campaigns-nav-button kb__campaigns-prev">
                      <img src={IconChevronLeft} alt="" />
                    </button>
                    
                    <div className="kb__campaigns-pagination"></div>

                    <button className="kb__campaigns-nav-button kb__campaigns-next">
                      <img src={IconChevronRight} alt="" />
                    </button>
                  </div>
                  
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </section>
  )

}

export default Marketplace