import React from 'react'

import './supportCards.scss'

const SupportCards = () => {

  return(
    
    <section className="kb__support-cards">
      <div className="kb__container">
        <div className="kb__grid-center">
          <div className="kb__col-5_md-6_sm-12" data-push-right="off-1">
            <a className="kb__card-link" href="https://intercom.help/kickbooster/" title="Visit our Help Center">
              <div className="kb__card has-border">
                
                <span className="kb__card-icon">
                  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M58 4H6C2.68625 4 0 6.68625 0 10V54C0 57.3138 2.68625 60 6 60H58C61.3138 60 64 57.3138 64 54V10C64 6.68625 61.3138 4 58 4ZM57.25 54H6.75C6.55109 54 6.36032 53.921 6.21967 53.7803C6.07902 53.6397 6 53.4489 6 53.25V10.75C6 10.5511 6.07902 10.3603 6.21967 10.2197C6.36032 10.079 6.55109 10 6.75 10H57.25C57.4489 10 57.6397 10.079 57.7803 10.2197C57.921 10.3603 58 10.5511 58 10.75V53.25C58 53.4489 57.921 53.6397 57.7803 53.7803C57.6397 53.921 57.4489 54 57.25 54ZM52 42.5V45.5C52 46.3284 51.3284 47 50.5 47H25.5C24.6716 47 24 46.3284 24 45.5V42.5C24 41.6716 24.6716 41 25.5 41H50.5C51.3284 41 52 41.6716 52 42.5ZM52 30.5V33.5C52 34.3284 51.3284 35 50.5 35H25.5C24.6716 35 24 34.3284 24 33.5V30.5C24 29.6716 24.6716 29 25.5 29H50.5C51.3284 29 52 29.6716 52 30.5ZM52 18.5V21.5C52 22.3284 51.3284 23 50.5 23H25.5C24.6716 23 24 22.3284 24 21.5V18.5C24 17.6716 24.6716 17 25.5 17H50.5C51.3284 17 52 17.6716 52 18.5ZM20.5 20C20.5 22.4853 18.4853 24.5 16 24.5C13.5147 24.5 11.5 22.4853 11.5 20C11.5 17.5147 13.5147 15.5 16 15.5C18.4853 15.5 20.5 17.5147 20.5 20ZM20.5 32C20.5 34.4853 18.4853 36.5 16 36.5C13.5147 36.5 11.5 34.4853 11.5 32C11.5 29.5147 13.5147 27.5 16 27.5C18.4853 27.5 20.5 29.5147 20.5 32ZM20.5 44C20.5 46.4852 18.4853 48.5 16 48.5C13.5147 48.5 11.5 46.4852 11.5 44C11.5 41.5148 13.5147 39.5 16 39.5C18.4853 39.5 20.5 41.5148 20.5 44Z" fill="#3A3A3A"/>
                  </svg>
                </span>

                <h4>Visit our Help Center</h4>

                <p>Get immediate answers by reading frequently asked questions in the Kickbooster Help Center. If you still have questions after checking it out, feel free to email us.</p>
                
              </div>
            </a>
          </div>

          <div className="kb__col-5_md-6_sm-12">
            <div className="kb__card with-dropshadow">
              
              <span className="kb__card-icon">
                <svg width="64" height="48" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M62.7875 15.85C63.275 15.4625 64 15.825 64 16.4375V42C64 45.3125 61.3125 48 58 48H6C2.6875 48 0 45.3125 0 42V16.45C0 15.825 0.7125 15.475 1.2125 15.8625C4.0125 18.0375 7.725 20.8 20.475 30.0625C23.1125 31.9875 27.5625 36.0375 32 36.0125C36.4625 36.05 41 31.9125 43.5375 30.0625C56.2875 20.8 59.9875 18.025 62.7875 15.85ZM32 32C34.9 32.05 39.075 28.35 41.175 26.825C57.7625 14.7875 59.025 13.7375 62.85 10.7375C63.575 10.175 64 9.3 64 8.375V6C64 2.6875 61.3125 0 58 0H6C2.6875 0 0 2.6875 0 6V8.375C0 9.3 0.425 10.1625 1.15 10.7375C4.975 13.725 6.2375 14.7875 22.825 26.825C24.925 28.35 29.1 32.05 32 32Z" fill="#3A3A3A"/>
                </svg>
              </span>

              <h4>Contact us</h4>

              <p>For quicker answers, please visit our Help Center. If you still have questions feel free to contact by clicking on the chat window below, or by emailing us at <a href="mailto:team@kickbooster.me" title="Contact Kickbooster by email">team@kickbooster.me</a> and we will get back to you as soon as possible.</p>
              
            </div>
          </div>
        </div>  
      </div>  
    </section>
  )
}

export default SupportCards